import axios from "axios";
import Config from "../../config";
const API_KEY = Config.apikey;
const ROOT_URL = Config.defurl;

const APIKEYIDAMAN = "Bearer "; 
 
export function cekAuth() {
  return {
    headers: { Authorization: API_KEY + localStorage.getItem("token") }
  };
}

const url = `${ROOT_URL}api/api-token-auth/`;

export function fetchToken(username, password) {
  console.log(url)
  const request = axios.post(
    url,
    {
      username: username,
      password: password
    },
    cekAuth()
  );
  return request;
}

export function fetchEmailExist(username) {
  let url = `${ROOT_URL}api/profile/?search=${username}`;

  const request = axios.get(url, cekAuth());
  return request;
}

export function authVerify() {
  const url = `${ROOT_URL}api/login/`;
  const request = axios.post(
    url,
    { token: localStorage.getItem("token") },
    cekAuth()
  );

  return request;
}

 
export function pushAddUser(data) {
  const url = `${ROOT_URL}api/profile/`;

  const fdata = new FormData();  
  fdata.append('email', data.email);
  fdata.append('name', data.name); 
  fdata.append('brithday', '1990-01-01');
  fdata.append('gender', 'male'); 
  fdata.append('login_type', '-'); 
  fdata.append('picture_background_url', ''); 
  fdata.append('picture_url', ''); 
  fdata.append('social_id', ''); 
  fdata.append('description', '-'); 
  fdata.append('job', '-'); 
  fdata.append('company_code', data.company_code); 
  fdata.append('userlevel', 0); 
  fdata.append('otp', ''); 
  fdata.append('oauth', '-');  
  fdata.append('password', data.password);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function pushAddAcc(data) {
  const url = `${ROOT_URL}api/profile/`;

  const fdata = new FormData();  
  fdata.append('email', data.email);
  fdata.append('name', data.name); 
  fdata.append('brithday', '1990-01-01');
  fdata.append('gender', 'male'); 
  fdata.append('login_type', '0'); 
  fdata.append('picture_background_url', ''); 
  fdata.append('picture_url', ''); 
  fdata.append('social_id', ''); 
  fdata.append('description', '-'); 
  fdata.append('job', '-'); 
  fdata.append('company_code', data.company_code); 
  fdata.append('userlevel', 0); 
  fdata.append('otp', ''); 
  fdata.append('oauth', '-');  
  fdata.append('password', data.password);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function registerNewCompany(data) {
  const url = `${ROOT_URL}company/signupcompany/`;

  const fdata = new FormData();  
  fdata.append('fileupload', data.fileupload);
  fdata.append('company_name', data.company_name); 
  fdata.append('company_address', data.company_address);
  fdata.append('company_telp', data.company_telp);
  fdata.append('company_email', data.company_email);
  fdata.append('npwp', data.npwp);  
  fdata.append('timezone_offset_hour', data.timezone_offset_hour);
  fdata.append('timezone_offset_minute', data.timezone_offset_minute);
  fdata.append('timezone_name', data.timezone_name);
  fdata.append('password', data.password);
  const request = axios.post(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function registerCompany(data) {
	const url = `${ROOT_URL}company/company/`;

	const fdata = new FormData();
	fdata.append('company_name', data.company_name);
	fdata.append('company_code', data.company_code);
	fdata.append('company_email', data.company_email);
  fdata.append('company_address', data.company_address);
  fdata.append('company_telp', data.company_telp);
  fdata.append('company_img', data.company_img);
	fdata.append('plan_id', data.plan_id.id);
	const request = axios.post(url, fdata, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}

export function pushImageCompany(data) {
  const url = `${ROOT_URL}company/uploadphotocompany/`
  const fdata = new FormData();
  fdata.append('fileupload', data[0]);
  const request = axios.post(url, fdata, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}

export function fetchEmailFGSearch(code) {
  const url = `${ROOT_URL}forgotpassword/forgotpassword/?search=${code}`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function fetchProfileSearch(email) {
  const url = `${ROOT_URL}api/profile/?search=${email}`;
  const request = axios.get(url, cekAuth());
  return request;
}

export function pushProfilePass(id, name, email, password) {
  const url = `${ROOT_URL}api/profile/${id}/`;

  const fdata = new FormData();
  fdata.append("password", password); 
  fdata.append("email", email);
  fdata.append("name", name); 

  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function fetchProfile(id) {
  const url = `${ROOT_URL}api/profile/${id}/`;
  const request = axios.get(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function pushProfile(
  id,
  name,
  gender,
  brithday,
  description,
  job,
  picture_url,
  company_code,
  email,
  loginType,
  socialId,
  userlevel
) {
  const url = `${ROOT_URL}api/profileex/${id}/`;

  const fdata = new FormData();
  fdata.append("name", name);
  // fdata.append("gender", gender);
  // fdata.append("brithday", brithday);
  fdata.append("description", description);
  // fdata.append("job", job);
  // fdata.append("company_code", company_code);
  fdata.append('email', email);
  // fdata.append('login_type', loginType);
  // fdata.append("picture_background_url", "-");
  // fdata.append('social_id', socialId);
  // fdata.append('userlevel', userlevel)

  // if (picture_url != "") {
  fdata.append("picture_url", picture_url);
  // }

  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function editPass(id, password, name, email) {
  const url = `${ROOT_URL}api/profile/${id}/`;

  const fdata = new FormData();
  fdata.append("name", name);
  fdata.append('email', email);
  fdata.append("password", password); 

  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function deleteAccount(id) {
  const url = `${ROOT_URL}api/profile/${id}/`;
  const request = axios.delete(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function pushProfilePictureUser(data) {
  const url = `${ROOT_URL}api/uploadphotoprofile/`
  const fdata = new FormData();
  fdata.append('fileupload', data[0]);
  const request = axios.post(url, fdata, cekAuth(), {
		onUploadProgress: (progressEvent) => {
			console.log(progressEvent.loaded / progressEvent.total);
		},
	});
	return request;
}

export function fetchUsers(companyCode) {
  const url = `${ROOT_URL}api/profile/?search=${companyCode}`;
  const request = axios.get(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function verificationEmail(encryptedEmail) {
  const url = `${ROOT_URL}company/verifysignup/?params=${encryptedEmail}`;
  const request = axios.get(url, cekAuth(), {
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total);
    }
  });
  return request;
}

export function editCompany(companyData, picture_url) {
  const url = `${ROOT_URL}company/company/${companyData.id}/`;

  const fdata = new FormData();
  fdata.append('company_name', companyData.company_name);
  fdata.append('company_address', companyData.company_address);
  fdata.append('company_telp', companyData.company_telp);
  fdata.append('company_img', picture_url);
  fdata.append('npwp', companyData.npwp);
  const request = axios.put(url, fdata, cekAuth(), {
    onUploadProgress: (progressEvent) => {
      console.log(progressEvent.loaded / progressEvent.total);
    },
  });
  return request;
}