import { combineReducers } from "redux"; 
import Token from "./login/tokenx"; 
import GetSpeedTarget from "./portal/get_speed_target";
import GetBaseMapAsli from "./portal/get_basemapasli";
import ChangeTools from './portal/get_tools';
import OpenRight from './portal/get_openRight';
import LayerMap from './portal/get_layer_map';
import getPoiTarget from './portal/get_poi_target';
import getFocusLayer from './portal/get_focus_layer';
import OpenPanelDrawing from './portal/get_open_panel_drawing'
import OpenMyFleet from './portal/get_open_myfleet'
import MyFleet from './portal/get_my_fleet';
import MyFleetSource from './portal/get_myfleet_source';
import FleetCategory from './portal/get_fleet_category';

const rootReducer = combineReducers({   
  token: Token, 
  speedTarget: GetSpeedTarget,
  basemapAsli: GetBaseMapAsli,
  changeTools: ChangeTools,
  openRight: OpenRight,
  layerMap: LayerMap,
  poiTarget: getPoiTarget,
  focusLayer: getFocusLayer,
  openPanelDrawing: OpenPanelDrawing,
  openMyFleet: OpenMyFleet,
  myFleet: MyFleet,
  myFleetSource: MyFleetSource,
  fleetCategory: FleetCategory
});

export default rootReducer;
