import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import { componentPagesMenu } from '../menu';
import axios, { CancelTokenStatic } from 'axios';
import Button from './bootstrap/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import config from '../config';
let CancelToken = {
	pertaminaFleet: axios.CancelToken,
	fleet: axios.CancelToken,
	port: axios.CancelToken,
	poi: axios.CancelToken,
	line: axios.CancelToken,
	area: axios.CancelToken,
};
let cancel: {
	pertaminaFleetRequest: any;
	vesselRequest: any;
	portRequest: any;
	poiRequest: any;
	lineRequest: any;
	areaRequest: any;
} = {
	pertaminaFleetRequest: undefined,
	vesselRequest: undefined,
	portRequest: undefined,
	poiRequest: undefined,
	lineRequest: undefined,
	areaRequest: undefined,
};

let ApiEndPoint = {
	gpsFleet: 'gpsapirt/gpsapirt/',
	aisFleet: 'fleetrt/fleetrt/',
	port: 'poi/poi/',
	poi: 'poi/poi/',
	line: 'line/line/',
	area: 'polygon/polygon/',
};

interface iPertaminaFleet {
	call_sign: string;
	country: string;
	country_code: string;
	course: string;
	date_created: string;
	dest_class: string;
	destination: string;
	draught: string;
	dwt: string;
	elapsed: string;
	flag: string;
	geofenceport_name: string;
	geofenceport_status: number;
	geofencesungai_status: number;
	heading: null;
	id: number;
	imo: string;
	last_port_country: string;
	last_port_id: string;
	last_port_name: string;
	last_port_unlocode: string;
	last_pos: string;
	last_pos_tooltip: string;
	lat: string;
	length: string;
	lon: string;
	mmsi: string;
	next_port_country: string;
	next_port_id: string;
	next_port_name: string;
	next_port_unlocode: string;
	port_data_x: string;
	port_data_y: string;
	programer_id: number;
	remark: null;
	reported_draught: string;
	rot: string;
	ship_id: string;
	shipname: string;
	shiptype: number;
	show_load_status: string;
	source_api: string;
	speed: string;
	station_id: string;
	status_name: string;
	timezone_offset: string;
	type_img: number;
	type_name: string;
	type_summary: string;
	vessel_category: string;
	vessel_container: string | null;
	vessel_status: string;
	vsl_code: string;
	vsl_length: string;
	width: string;
}

interface iFleet {
	call_sign: string | null;
	country: string | null;
	country_code: string | null;
	course: string | null;
	date_created: string | null;
	dest_class: string | null;
	destination: string | null;
	draught: string | null;
	dwt: string | null;
	elapsed: string | null;
	eta: string | null;
	flag: string | null;
	gt: string | null;
	heading: string | null;
	id: number;
	imo: string | null;
	last_port_country: string | null;
	last_port_id: string | null;
	last_port_name: string | null;
	last_port_unlocode: string | null;
	last_pos: string | null;
	last_pos_tooltip: string | null;
	lat: string | null;
	length: string | null;
	loa: string | null;
	lon: string | null;
	mmsi: string | null;
	next_port_country: string | null;
	next_port_id: string | null;
	next_port_name: string | null;
	next_port_unlocode: string | null;
	plot_status: string | null;
	port_data_x: string | null;
	port_data_y: string | null;
	reported_draught: string | null;
	rot: string | null;
	ship_id: string | null;
	shipname: string | null;
	shiptype: string | null;
	show_load_status: string | null;
	source_api: string | null;
	speed: string | null;
	station_id: string | null;
	status_name: string | null;
	timezone_offset: string | null;
	type_img: string | null;
	type_name: string | null;
	type_summary: string | null;
	update_on: string | null;
	vsl_code: string | null;
	vsl_length: string | null;
	width: string | null;
}

interface iPort {
	address: string;
	category: string;
	description: string;
	gencode: string;
	geom: string;
	iconpath: string;
	id: number;
	imgpath: string;
	latitude: string;
	longitude: string;
	name: string;
}

interface iDrawing {
	category: string;
	dateCreated: string;
	description: string;
	gencode: string;
	geom: string;
	geomall: string;
	id: number;
	name: string;
}

type TSearchCategory = 'Pertamina Fleet' | 'Fleet' | 'Port' | 'POI' | 'Line' | 'Area';

const SearchFleet = () => {
	const refSearchInput = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
    const myFleetSource = useSelector((state: RootStateOrAny) => state.myFleetSource);
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [pertaminaFleet, setPertaminaFleet] = useState<iPertaminaFleet[]>([]);
	const [fleet, setFleet] = useState<iFleet[]>([]);
	const [port, setPort] = useState<iPort[]>([]);
	const [poi, setPoi] = useState<iPort[]>([]);
	const [line, setLine] = useState<iDrawing[]>([]);
	const [area, setArea] = useState<iDrawing[]>([]);
	const [loaderPertaminaFleet, setLoaderPertaminaFleet] = useState(false);
	const [loaderFleet, setLoaderFleet] = useState(false);
	const [loaderPort, setLoaderPort] = useState(false);
	const [loaderPoi, setLoaderPoi] = useState(false);
	const [loaderLine, setLoaderLine] = useState(false);
	const [loaderArea, setLoaderArea] = useState(false);
	const [activeCategory, setActiveCategory] = useState<TSearchCategory>('Fleet');
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setSearchModalStatus(true);
		},
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (formik.values.searchInput) {
			setSearchModalStatus(true);
			refSearchInput?.current?.focus();
			// handleSearch(
			// 	CancelToken.pertaminaFleet,
			// 	cancel.pertaminaFleetRequest,
			// 	ApiEndPoint.pertaminaFleet,
			// 	'Pertamina Fleet',
			// 	setPertaminaFleet,
			// 	setLoaderPertaminaFleet,
			// );
			if (activeCategory === 'Fleet') {
				handleSearch(
					CancelToken.fleet,
					cancel.vesselRequest,
					myFleetSource == "AIS" ? ApiEndPoint.aisFleet : ApiEndPoint.gpsFleet,
					activeCategory,
					setFleet,
					setLoaderFleet,
				);
			} else if (activeCategory === 'Port') {
				handleSearch(
					CancelToken.port,
					cancel.portRequest,
					ApiEndPoint.port,
					activeCategory,
					setPort,
					setLoaderPort,
				);
			} else if (activeCategory === 'POI') {
				handleSearch(
					CancelToken.poi,
					cancel.poiRequest,
					ApiEndPoint.poi,
					activeCategory,
					setPoi,
					setLoaderPoi,
				);
			} else if (activeCategory === 'Line') {
				handleSearch(
					CancelToken.line,
					cancel.lineRequest,
					ApiEndPoint.line,
					activeCategory,
					setLine,
					setLoaderLine,
				);
			} else if (activeCategory === 'Area') {
				handleSearch(
					CancelToken.area,
					cancel.areaRequest,
					ApiEndPoint.area,
					activeCategory,
					setArea,
					setLoaderArea,
				);
			}
		}
		return () => {
			setSearchModalStatus(false);
		};
	}, [formik.values.searchInput, activeCategory]);

	const handleSearch = async (
		cancelToken: CancelTokenStatic,
		cancelFunc: any,
		apiEndpoint: string,
		type: TSearchCategory,
		setValue: React.Dispatch<React.SetStateAction<any>>,
		setLoader: React.Dispatch<React.SetStateAction<boolean>>,
	) => {
		await setValue([]);
		await setLoader(true);
		if (cancelFunc) {
			cancelFunc();
		}
		const API_KEY = config.apikey;
		// const ROOT_URL = Config.defurl;
		const companyCode = localStorage.getItem('company_code');
		const ROOT_URL = `https://${companyCode}.exabit.co.id/`;
		await axios
			.get(`${ROOT_URL}${apiEndpoint}?search=${formik.values.searchInput}&limit=10`, {
				headers: {
					Authorization: API_KEY + localStorage.getItem('token'),
				},
				cancelToken: new cancelToken(function executor(c) {
					if (type === 'Pertamina Fleet') {
						cancel.pertaminaFleetRequest = c;
					} else if (type === 'Fleet') {
						cancel.vesselRequest = c;
					} else if (type === 'Port') {
						cancel.portRequest = c;
					} else if (type === 'POI') {
						cancel.poiRequest = c;
					} else if (type === 'Line') {
						cancel.lineRequest = c;
					} else if (type === 'Area') {
						cancel.areaRequest;
					}
				}),
			})
			.then((res) => {
				console.log(res.data);
				setValue(res.data.results);
				setLoader(false);
			})
			.catch((err) => {
				console.log(err);
				if (!axios.isCancel(err)) {
					setLoader(false);
				}
			});
	};

	const searchPages: {
		[key: string]: {
			id: string;
			text: string;
			path: string;
			icon: string;
		};
	} = {
		...componentPagesMenu.components.subMenu,
		...componentPagesMenu.content.subMenu,
		...componentPagesMenu.forms.subMenu,
		...componentPagesMenu.utilities.subMenu,
		...componentPagesMenu.icons.subMenu,
		...componentPagesMenu.charts.subMenu,
	};
	const filterResult = Object.keys(searchPages)
		.filter(
			(key) =>
				searchPages[key].text
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()) ||
				searchPages[key].path
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()),
		)
		.map((i) => searchPages[i]);

	const handleChangeCategory = (type: TSearchCategory) => {
		setActiveCategory(type);
	};

	const handleResultPertaminaFleet = () => {
		return (
			<>
				<thead className='position-sticky' style={{ top: -13 }}>
					<tr>
						<th scope='col'>Pertamina Fleet</th>
					</tr>
				</thead>
				<tbody>
					{loaderPertaminaFleet ? (
						<tr className='table-active'>
							<td>Loading Data</td>
						</tr>
					) : pertaminaFleet.length ? (
						pertaminaFleet.map((item) => (
							<tr
								key={item.id}
								className='cursor-pointer'
								onClick={() => {
									// navigate(`../${item.path}`);
								}}>
								<td>
									{item.shipname} - {item.ship_id}
								</td>
							</tr>
						))
					) : (
						<tr className='table-active'>
							<td>
								No Pertamina Fleet found for keyword "{formik.values.searchInput}"
							</td>
						</tr>
					)}
				</tbody>
			</>
		);
	};

	const handleResultFleet = () => {
		return (
			<>
				<thead className='position-sticky' style={{ top: -13 }}>
					<tr>
						<th scope='col'>Fleet</th>
					</tr>
				</thead>
				<tbody>
					{loaderFleet ? (
						<tr className='table-active'>
							<td>Loading Data</td>
						</tr>
					) : fleet.length ? (
						fleet.map((item) => (
							<tr
								key={item.id}
								className='cursor-pointer'
								onClick={() => {
									// navigate(`../${item.path}`);
								}}>
								<td onClick={() => handleDispatch(item)}>
									<Link to={'/'}>
										{item.shipname} - {item.call_sign}
									</Link>
								</td>
							</tr>
						))
					) : (
						<tr className='table-active'>
							<td>No Fleet found for keyword "{formik.values.searchInput}"</td>
						</tr>
					)}
				</tbody>
			</>
		);
	};

	const handleResultPort = (type: 'Port' | 'Poi') => {
		let loader = type === 'Poi' ? loaderPoi : loaderPort;
		let data = type === 'Poi' ? poi : port;
		return (
			<>
				<thead className='position-sticky' style={{ top: -13 }}>
					<tr>
						<th scope='col'>{type}</th>
					</tr>
				</thead>
				<tbody>
					{loader ? (
						<tr className='table-active'>
							<td>Loading Data</td>
						</tr>
					) : data.length ? (
						data.map((item) => (
							<tr
								key={item.id}
								className='cursor-pointer'
								onClick={() => handleDispatch(item)}>
								<td>
									<Link to={'/'}>
										{item.name} - {item.address}
									</Link>
								</td>
							</tr>
						))
					) : (
						<tr className='table-active'>
							<td>No Port found for keyword "{formik.values.searchInput}"</td>
						</tr>
					)}
				</tbody>
			</>
		);
	};

	const handleResultDrawing = (type: 'Line' | 'Area', data: iDrawing[], loader: boolean) => {
		return (
			<>
				<thead className='position-sticky' style={{ top: -13 }}>
					<tr>
						<th scope='col'>{type}</th>
					</tr>
				</thead>
				<tbody>
					{loader ? (
						<tr className='table-active'>
							<td>Loading Data</td>
						</tr>
					) : data.length ? (
						data.map((item) => (
							<tr
								key={item.id}
								className='cursor-pointer'
								onClick={() => {
									// navigate(`../${item.path}`);
								}}>
								<td onClick={() => handleDispatch(item)}>
									<Link to={'/'}>
										{item.category} - {item.name}
									</Link>
								</td>
							</tr>
						))
					) : (
						<tr className='table-active'>
							<td>No Drawing found for keyword "{formik.values.searchInput}"</td>
						</tr>
					)}
				</tbody>
			</>
		);
	};

	const handleDispatch = (item: any) => {
		dispatch({
			type: 'POITARGET',
			payload: {
				...item,
				typeSearch: activeCategory
			},
		});
		setSearchModalStatus(false);
	};

	return (
		<>
			<div className='d-flex border border-primary border-1 rounded' data-tour='search'>
				<label
					className='border-0 bg-transparent cursor-pointer'
					htmlFor='searchInput'
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Search...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
				/>
			</div>
			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus}>
					<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						ref={refSearchInput}
						name='searchInput'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search...'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</ModalHeader>
				<ModalBody>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: 8,
						}}>
						{/* <Button
							color='primary'
							isLight
							isActive={activeCategory === 'Pertamina Fleet'}
							onClick={() => handleChangeCategory('Pertamina Fleet')}>
							Pertamina Fleet
						</Button> */}
						<Button
							color='primary'
							isLight
							isActive={activeCategory === 'Fleet'}
							onClick={() => handleChangeCategory('Fleet')}>
							Fleet
						</Button>
						<Button
							color='primary'
							isLight
							isActive={activeCategory === 'Port'}
							onClick={() => handleChangeCategory('Port')}>
							Port
						</Button>
						<Button
							color='primary'
							isLight
							isActive={activeCategory === 'POI'}
							onClick={() => handleChangeCategory('POI')}>
							POI
						</Button>
						<Button
							color='primary'
							isLight
							isActive={activeCategory === 'Line'}
							onClick={() => handleChangeCategory('Line')}>
							Line
						</Button>
						<Button
							color='primary'
							isLight
							isActive={activeCategory === 'Area'}
							onClick={() => handleChangeCategory('Area')}>
							Area
						</Button>
					</div>
					<table className='table table-hover table-modern caption-top mb-0'>
						<caption>
							Results:{' '}
							{activeCategory === 'Pertamina Fleet'
								? pertaminaFleet.length
								: activeCategory === 'Fleet'
								? fleet.length
								: port.length}
						</caption>
						{/* {activeCategory === 'Pertamina Fleet' && handleResultPertaminaFleet()} */}
						{activeCategory === 'Fleet' && handleResultFleet()}
						{activeCategory === 'Port' && handleResultPort('Port')}
						{activeCategory === 'POI' && handleResultPort('Poi')}
						{activeCategory === 'Line' && handleResultDrawing('Line', line, loaderLine)}
						{activeCategory === 'Area' && handleResultDrawing('Area', area, loaderArea)}
					</table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default SearchFleet;
