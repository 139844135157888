import React, { FC, createRef, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import {
	fetchToken,
	pushAddUser,
	pushImageCompany,
	registerCompany,
	registerNewCompany,
} from '../../../api/login/index';
import { useSelector, useDispatch, connect, RootStateOrAny } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import config from '../../../config';
import Item from '../../../layout/Navigation/Item';
import Config from '../../../config';
import timezoneData from '../../../common/data/timezone';

interface ILoginHeaderProps {
	isNewUser?: boolean;
	sentEmailSuccess?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, sentEmailSuccess }) => {
	if (isNewUser) {
		if (sentEmailSuccess) {
			return (
				<>
					<div className='text-center h1 fw-bold mt-5'>Email sent!</div>
					<div className='text-center h4 text-muted mb-5'>
						Please check your email to verify your account.
					</div>
				</>
			);
		}
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const dispatch = useDispatch();

	const speedTarget = useSelector((state: RootStateOrAny) => state.speedTarget);

	const token = useSelector((state: RootStateOrAny) => state.token);

	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [signUpType, setSignUpType] = useState<'User' | 'Company'>('User');
	const [companyImage, setCompanyImage] = useState<string>('');
	const [companyImageForm, setCompanyImageForm] = useState<FileList>();
	const [plan, setPlan] = useState<
		{
			name: string;
			price: string;
			id: number;
		}[]
	>([]);
	const [selectedPlan, setSelectedPlan] = useState<{
		name: string;
		price: string;
		id: number;
	}>({
		id: 0,
		name: '',
		price: '',
	});
	const [useForUser, setUseForUser] = useState<'Personal' | 'Company'>('Company');
	const [errorSignUpUserCompany, setErrorSignUpUserCompany] = useState(false);
	const [errorMessageSignUpCompany, setErrorMessageSignUpCompany] = useState('')
	const [errorSignUpCompanyEmail, setErrorSignUpCompanyEmail] = useState(false);
	const [errorLogin, setErrorLogin] = useState(false);
	const [successSendEmailCompany, setsuccessSendEmailCompany] = useState(false);
	const [errorSignUpUserEmail, setErrorSignUpUserEmail] = useState(false);
	const [successSendEmailUser, setsuccessSendEmailUser] = useState(false);
	const [imageStatus, setImageStatus] = useState<'Not Yet Working' | 'Error' | 'Success'>(
		'Not Yet Working',
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [signUpCompanyCode, setSignUpCompanyCode] = useState<string>('')
	const [listTimeZone, setListTimeZone] = useState<string>('');
	const [checkTimezone, setCheckTimezone] = useState<boolean>(true);


	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const getTimezone = () => {
		let data: any = [];
		timezoneData.map((item) => {
			data.push("(" +item.name+ ")" +" "+ item.gmt);
		})		
		 return data
	}

	useEffect(() => {
		axios
			.get(`${config.defurl}pricelist/pricelist/`)
			.then((res) => {
				let planList: {
					id: number;
					price_type: string;
					price: string;
					service_description: string;
				}[] = res.data.results;
				let temp: {
					name: string;
					price: string;
					id: number;
				}[] = [];
				planList.forEach((Item) => {
					temp.push({
						id: Item.id,
						name: Item.price_type,
						price: Item.price,
					});
				});
				setPlan(temp);
				setSelectedPlan(temp[0]);
			})
			.catch((err) => {
				console.log(err);
			});

	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			signupName: '',
			signupUserCompanyCode: '',
			signupPassword: '',
			signupEmail: '',
			//Company Form
			signUpCompanyEmail: '',
			signUpCompanyName: '',
			signUpCompanyCode: '',
			signUpCompanyImage: '',
			signUpCompanyPlan: '',
			signUpCompanyPassword: '',
			signUpCompanyAddress: '',
			signUpCompanyPhoneNumber: '',
			signUpCompanyNpwp: '',
		},
		validate: (values) => {
			const errors: {
				loginUsername?: string;
				loginPassword?: string;
				signupName?: string;
				signupUserCompanyCode?: string;
				signupPassword?: string;
				signupEmail?: string;
				signUpCompanyEmail?: string;
				signUpCompanyName?: string;
				signUpCompanyCode?: string;
				signUpCompanyImage?: string;
				signUpCompanyPlan?: string;
				signUpCompanyNpwp?: string;
			} = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			if (!values.signupName) {
				errors.signupName = 'Required';
			}

			if (!values.signupUserCompanyCode) {
				errors.signupUserCompanyCode = 'Required';
			}

			if (!values.signupEmail) {
				errors.signupEmail = 'Required';
			}

			if (!values.signupPassword) {
				errors.signupPassword = 'Required';
			}

			if (!values.signUpCompanyEmail) {
				errors.signUpCompanyEmail = 'Required';
			}

			if (!values.signUpCompanyName) {
				errors.signUpCompanyName = 'Required';
			}

			if (!values.signUpCompanyCode) {
				errors.signUpCompanyCode = 'Required';
			}

			if (!values.signUpCompanyImage) {
				errors.signUpCompanyImage = 'Required';
			}

			if (!values.signUpCompanyPlan) {
				errors.signUpCompanyPlan = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			if (values.signUpCompanyNpwp.toString().length !== 16) {
				errors.signUpCompanyNpwp = 'NPWP must have 16 numbers'
			}

			if (!values.signUpCompanyNpwp) {
				errors.signUpCompanyNpwp = 'Required'
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginUsername);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});

	const handleRenderSignUp = () => {
		if (successSendEmailCompany || successSendEmailUser) {
			return;
		} else {
			return (
				<React.Fragment>
					<div
						className={classNames('rounded-3', {
							'bg-l10-dark': !darkModeStatus,
							'bg-dark': darkModeStatus,
						})}>
						<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
							<div className='col'>
								<Button
									color={darkModeStatus ? 'light' : 'dark'}
									isLight={signUpType === 'Company'}
									className='rounded-1 w-100'
									size='sm'
									onClick={() => {
										setSignUpType('User');
									}}>
									User
								</Button>
							</div>
							<div className='col'>
								<Button
									color={darkModeStatus ? 'light' : 'dark'}
									isLight={signUpType === 'User'}
									className='rounded-1 w-100'
									size='sm'
									onClick={() => {
										setSignUpType('Company');
									}}>
									Company
								</Button>
							</div>
						</div>
					</div>
					{signUpType === 'User' ? handleSignUpUser() : handleSignUpCompany()}
					<div className='col-12'>
						{errorSignUpCompanyEmail ? (
							<Button
								color='info'
								className='w-100 py-3'
								onClick={handleReSent}
								isDisable={isLoading}>
								{isLoading && <Spinner isSmall inButton isGrow />}
								Resent Email
							</Button>
						) : (
							<Button
								color='info'
								className='w-100 py-3'
								onClick={handleSignUp}
								isDisable={isLoading}>
								{isLoading && <Spinner isSmall inButton isGrow />}
								Sign Up
							</Button>
						)}
						<p
							hidden={!errorSignUpUserCompany}
							style={{
								color: '#f35421',
							}}>
							{errorMessageSignUpCompany}
						</p>
					</div>
				</React.Fragment>
			);
		}
	};

	const handleSignUpUser = () => {
		return (
			<React.Fragment>
				<div className='col-12'>
					<FormGroup isFloating label='Your email' labelClassName='text-info'>
						<Input
							type='email'
							autoComplete='email'
							id='signupEmail'
							value={formik.values.signupEmail}
							isTouched={formik.touched.signupEmail}
							invalidFeedback={formik.errors.signupEmail}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup
						isFloating
						onBlur={formik.handleBlur}
						onFocus={() => {
							formik.setErrors({});
						}}
						label='Your name'
						labelClassName='text-info'>
						<Input
							autoComplete='given-name'
							id='signupName'
							value={formik.values.signupName}
							isTouched={formik.touched.signupName}
							invalidFeedback={formik.errors.signupName}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				{/* <div className='col-12'>
					<p
						style={{
							margin: '0',
						}}>
						Use For
					</p>
				</div> */}
				{/* <div
					className='col-12'
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						gap: '10px',
						margin: 0,
					}}>
					<Button
						style={{
							width: '100%',
						}}
						color='primary'
						isLight={useForUser !== 'Personal'}
						onClick={() => {
							setUseForUser('Personal');
							formik.setFieldValue('signupUserCompanyCode', 'rti001');
						}}>
						<p
							style={{
								margin: '0px',
							}}>
							Personal
						</p>
					</Button>
					<Button
						style={{
							width: '100%',
						}}
						color='primary'
						isLight={useForUser !== 'Company'}
						onClick={() => {
							setUseForUser('Company');
							formik.setFieldValue('signupUserCompanyCode', '');
						}}>
						<p
							style={{
								margin: '0px',
							}}>
							Company
						</p>
					</Button>
				</div> */}
				{useForUser === 'Company' ? (
					<div className='col-12'>
						<FormGroup isFloating label='Company Code'
						labelClassName='text-info'>
							<Input
								id='signupUserCompanyCode'
								value={formik.values.signupUserCompanyCode}
								isTouched={formik.touched.signupUserCompanyCode}
								invalidFeedback={formik.errors.signupUserCompanyCode}
								isValid={formik.isValid}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								onFocus={() => {
									formik.setErrors({});
								}}
							/>
						</FormGroup>
					</div>
				) : (
					<div
						className='col-12'
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							gap: '10px',
						}}></div>
				)}
				<div className='col-12'>
					<FormGroup isFloating label='Password'
					labelClassName='text-info'>
						<Input
							type='password'
							autoComplete='password'
							id='signupPassword'
							value={formik.values.signupPassword}
							isTouched={formik.touched.signupPassword}
							invalidFeedback={formik.errors.signupPassword}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
			</React.Fragment>
		);
	};

	const handleSignUpCompany = () => {
		return (
			<React.Fragment>
				<div className='col-12'>
					<FormGroup
						isFloating
						onBlur={formik.handleBlur}
						onFocus={() => {
							formik.setErrors({});
						}}
						label='Company Name'
						labelClassName='text-info'>
						<Input
							autoComplete='given-name'
							id='signUpCompanyName'
							value={formik.values.signUpCompanyName}
							isTouched={formik.touched.signUpCompanyName}
							invalidFeedback={formik.errors.signUpCompanyName}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup isFloating label='Company Address'
					labelClassName='text-info'>
						<Input
							type='text'
							autoComplete='Address'
							id='signUpCompanyAddress'
							value={formik.values.signUpCompanyAddress}
							isTouched={formik.touched.signUpCompanyAddress}
							invalidFeedback={formik.errors.signUpCompanyAddress}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup isFloating label='Phone Number'
					labelClassName='text-info'>
						<Input
							type='number'
							autoComplete='phone'
							id='signUpCompanyPhoneNumber'
							value={formik.values.signUpCompanyPhoneNumber}
							isTouched={formik.touched.signUpCompanyPhoneNumber}
							invalidFeedback={formik.errors.signUpCompanyPhoneNumber}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup isFloating label='Company Email'
					labelClassName='text-info'>
						<Input
							type='email'
							autoComplete='email'
							id='signUpCompanyEmail'
							value={formik.values.signUpCompanyEmail}
							isTouched={formik.touched.signUpCompanyEmail}
							invalidFeedback={formik.errors.signUpCompanyEmail}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup isFloating label='Password'
					labelClassName='text-info'>
						<Input
							type='password'
							autoComplete='password'
							id='signUpCompanyPassword'
							value={formik.values.signUpCompanyPassword}
							isTouched={formik.touched.signUpCompanyPassword}
							invalidFeedback={formik.errors.signUpCompanyPassword}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup isFloating label='Timezone'
					labelClassName='text-info'>
						<Input
							id="timezone"
							list={getTimezone()}
							onChange={(e: any) => {
								setListTimeZone(e.target.value);								
								setCheckTimezone(true);
							}}
							value={listTimeZone}
							style={{borderColor: checkTimezone == false ? 'red' : 'none'}}
						/>						
						<span hidden={checkTimezone} style={{color: 'red'}}>{" "+ "Required!"}</span>
					</FormGroup>

				</div>
				<div className='col-12'>
					<FormGroup isFloating label='NPWP'
					labelClassName='text-info'>
						<Input
							type='number'
							autoComplete='NPWP'
							id='signUpCompanyNpwp'
							value={formik.values.signUpCompanyNpwp}
							isTouched={formik.touched.signUpCompanyNpwp}
							invalidFeedback={formik.errors.signUpCompanyNpwp}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<p
						style={{
							margin: '0',
						}}>
						Company Image
					</p>
					<Input
						type='file'
						id='signUpCompanyImage'
						onChange={handleInputImage}
						multiple={false}
					/>
				</div>
				<img hidden={!companyImage} src={companyImage} />
				<div className='col-12'>
					<Link to={'/plan'} target='_blank'>
						Want to know more detail about plan? Click here
					</Link>
				</div>
			</React.Fragment>
		);
	};

	const handleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setCompanyImage(URL.createObjectURL(e.target.files[0]));
			setCompanyImageForm(e.target.files);
		}
	};

	const handleSignUp = async () => {
		setIsLoading(true);
		if (signUpType === 'User') {
			const dataCreateUser = {
				name: formik.values.signupName,
				email: formik.values.signupEmail,
				password: formik.values.signupPassword,
				company_code: formik.values.signupUserCompanyCode,
				plan_id: '1',
			};

			dispatch({
				type: 'TOKEN',
				payload: 'uhuy',
			});
			pushAddUser(dataCreateUser)
				.then(async (response) => {
					if (response.data) {
						await axios
							.post(`${config.emailurl}sign-up`, {
								email: dataCreateUser.email,
							})
							.then((res) => {
								console.log(res);
								setsuccessSendEmailUser(true);
							})
							.catch((err) => {
								console.log(err);
								setErrorSignUpUserEmail(true);
							});
						navigate('/login');
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			if (
				companyImageForm &&
				formik.values.signUpCompanyName &&
				formik.values.signUpCompanyAddress &&
				formik.values.signUpCompanyPhoneNumber &&
				formik.values.signUpCompanyEmail &&
				formik.values.signUpCompanyNpwp &&
				formik.values.signUpCompanyPassword &&
				listTimeZone
			) {				
				setCheckTimezone(true);
				let valuenya = listTimeZone.split(") ");
				let zonename = valuenya[0].slice(1);
				let gmtZone = valuenya[1].split("(GMT");
				let gmtHour = gmtZone[1].slice(0, 3);								
				let gmtMinutes = gmtZone[1].slice(4, 6);
				registerNewCompany({
					fileupload: companyImageForm[0],
					company_name: formik.values.signUpCompanyName,
					company_address: formik.values.signUpCompanyAddress,
					company_telp: formik.values.signUpCompanyPhoneNumber,
					company_email: formik.values.signUpCompanyEmail,
					npwp: formik.values.signUpCompanyNpwp,
					password: formik.values.signUpCompanyPassword,
					timezone_offset_hour: gmtHour,
					timezone_offset_minute: gmtMinutes,
					timezone_name: zonename
				})
					.then(async (res) => {
						console.log(res.data);
						setSignUpCompanyCode(res.data.company_code)
						await axios
							.post(`${config.emailurl}sign-up-company`, {
								email: formik.values.signUpCompanyEmail,
								password: formik.values.signUpCompanyPassword,
								userStatus: !errorSignUpUserCompany,
								companyCode: res.data.company_code,
							})
							.then((res) => {
								console.log(res);
								setsuccessSendEmailCompany(true);
							})
							.catch((err) => {
								console.log(err);
								setErrorSignUpCompanyEmail(true);
							});
					})
					.catch(async(err) => {
						console.log(err);
						let msg = err.response.data.msg;
						setErrorSignUpUserCompany(true);
						setErrorMessageSignUpCompany(msg)
					});
			} else {
				setCheckTimezone(false);
			}
			// dispatch({
			// 	type: 'TOKEN',
			// 	payload: 'uhuy',
			// });
			// await pushImageCompany(companyImageForm)
			// 	.then(async (resImage) => {
			// 		console.log(resImage.data);
			// 		const dataCreateCompany = {
			// 			company_name: formik.values.signUpCompanyName,
			// 			company_code: Math.random().toString(36).slice(-6), //random generate company code
			// 			company_email: formik.values.signUpCompanyEmail,
			// 			company_address: '-',
			// 			company_telp: '-',
			// 			plan_id: selectedPlan,
			// 			company_img: resImage.data.url,
			// 		};

			// 		const dataCreateUser = {
			// 			name: formik.values.signUpCompanyName,
			// 			email: formik.values.signUpCompanyEmail,
			// 			password: Math.random().toString(36).slice(-8), //random generate password
			// 			company_code: formik.values.signUpCompanyCode,
			// 		};
			// 		formik.setFieldValue('signUpCompanyPassword', dataCreateUser.password);
			// 		await registerCompany(dataCreateCompany)
			// 			.then(async (response) => {
			// 				if (response.data) {
			// 					console.log(companyImageForm);
			// 					await pushAddUser(dataCreateUser)
			// 						.then(async (responseAddUser) => {
			// 							if (responseAddUser.data) {
			// 								setErrorSignUpUserCompany(true);
			// 								await axios
			// 									.post('http://localhost:8081/sign-up-company', {
			// 										email: dataCreateCompany.company_email,
			// 										password: dataCreateUser.password,
			// 										userStatus: !errorSignUpUserCompany,
			// 										companyCode: dataCreateCompany.company_code,
			// 									})
			// 									.then((res) => {
			// 										console.log(res);
			// 										setsuccessSendEmailCompany(true);
			// 									})
			// 									.catch((err) => {
			// 										console.log(err);
			// 										setErrorSignUpCompanyEmail(true);
			// 									});
			// 							}
			// 						})
			// 						.catch(async (errorAddUser) => {
			// 							console.log(errorAddUser);
			// 							setErrorSignUpUserCompany(false);
			// 							await axios
			// 								.post('http://localhost:8081/sign-up-company', {
			// 									email: dataCreateCompany.company_email,
			// 									password: dataCreateUser.password,
			// 									userStatus: errorSignUpUserCompany,
			// 									companyCode: dataCreateCompany.company_code,
			// 								})
			// 								.then((res) => {
			// 									console.log(res);
			// 									setsuccessSendEmailCompany(true);
			// 								})
			// 								.catch((err) => {
			// 									console.log(err);
			// 									setErrorSignUpCompanyEmail(true);
			// 								});
			// 						});
			// 				}
			// 			})
			// 			.catch((error) => {
			// 				console.log(error);
			// 			});
			// 	})
			// 	.catch((err) => {
			// 		console.log(err);
			// 	});
		}
		setIsLoading(false);
	};

	const handleReSent = async () => {
		setIsLoading(true);
		if (signUpType === 'Company') {
			await axios
				.post(`${config.emailurl}sign-up-company`, {
					email: formik.values.signUpCompanyEmail,
					password: formik.values.signUpCompanyPassword,
					userStatus: errorSignUpUserCompany,
					companyCode: signUpCompanyCode,
				})
				.then((res) => {
					console.log(res);
					setsuccessSendEmailCompany(true);
				})
				.catch((err) => {
					console.log(err);
					setErrorSignUpCompanyEmail(true);
				});
		} else {
			await axios
				.post(`${config.emailurl}sign-up`, {
					email: formik.values.signUpCompanyEmail,
				})
				.then((res) => {
					console.log(res);
					setsuccessSendEmailUser(true);
				})
				.catch((err) => {
					console.log(err);
					setErrorSignUpUserEmail(true);
				});
		}
		setIsLoading(false);
	};

	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			fetchToken(formik.values.loginUsername, formik.values.loginPassword)
				.then((response) => {
					if (!response.data) {
						formik.setFieldError('loginUsername', 'No such user found in the system.');
					} else {
						console.log(response.data);
						console.log('berhasil login');
						localStorage.setItem('id', response.data.user_id);
						localStorage.setItem('token', response.data.token);
						localStorage.setItem('name', response.data.name);
						localStorage.setItem('email', response.data.email);
						localStorage.setItem('userlevel', '0');
						localStorage.setItem('company_code', response.data.company_code);
						localStorage.setItem('picture_url', response.data.picture_url);
						localStorage.setItem('is_superuser', response.data.is_superuser);
						localStorage.setItem('timezone_offset_hour', response.data.timezone_offset_hour);
						setSignInPassword(true);
						setIsLoading(false);

						if (setUser) {
							setUser(response.data.name);
						}

						console.log(response.data.name);

						handleOnClick();
					}
				})
				.catch((error) => {
					setErrorLogin(true);
					console.log(error);
				});

			setIsLoading(false);
		}, 1000);
	};

	const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') return handleContinue()
	}

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader
									isNewUser={singUpStatus}
									sentEmailSuccess={
										successSendEmailCompany || successSendEmailUser
									}
								/>

								<Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {'admin@exabit.co.id'}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {'123qaz'}
										</div>
									</div>
								</Alert>
								<form className='row g-4'>
									{singUpStatus ? (
										handleRenderSignUp()
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Email'
													className={classNames({
														'd-none': signInPassword,
													})}
													labelClassName='text-info'>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
														onKeyUp={handleEnter}
													/>
												</FormGroup>
												<br />
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': signInPassword,
													})}
													labelClassName='text-info'>
													<Input
														autoComplete='password'
														type='password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
														onKeyUp={handleEnter}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}
													labelClassName='text-info'>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														Login
													</Button>
												)}
												<p
													hidden={!errorLogin}
													style={{
														color: '#f35421',
													}}>
													Login error, please check your email and
													password
												</p>
												<div
													style={{
														paddingRight: 0,
														position: 'relative',
														right: '0px',
													}}>
													<br />
													<a>
														<Link
															to={'/auth-pages/forgot-password'}
															style={{
																textDecoration: 'none',
															}}>
															{' '}
															Forgot your password?
														</Link>
													</a>
												</div>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											{/* <div className='col-12 mt-3 text-center text-muted'>
												OR
											</div> */}
											{/* <div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div> */}
											{/* <div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div> */}
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
