import axios from 'axios';
import Config from '../../config';
const API_KEY = Config.apikey;
// const ROOT_URL = Config.defurl;
const ROOT_URL = `https://${localStorage.getItem('company_code')}.exabit.co.id/`;
import CryptoJS from 'crypto-js';

export function cekAuth() {
	return {
		headers: { Authorization: API_KEY + localStorage.getItem('token') },
	};
}

export function fetchUseFor() {
	const url = `${ROOT_URL}drawsave/drawsaveusefor/`;
	const request = axios.get(url, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function postDrawSave(data) {
	const url = `${ROOT_URL}drawsave/drawsave/`;
	const request = axios.post(url, data, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function fetchCategoryDraw(types) {
	const url = `${ROOT_URL}catdrawsave/catdrawsave/?search=${types}`;
	const request = axios.get(url, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function pushCategoryDraw(data) {
	const fdata = new FormData();
	fdata.append('name', data.category);
	fdata.append('description', data.description);
	fdata.append('types', data.types);
	fdata.append('zonetracking_active', data.zonetracking_active)
	const url = `${ROOT_URL}catdrawsave/catdrawsave/`;
	const request = axios.post(url, fdata, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function pushDraw(tool, data) {
	const fdata = new FormData();
	fdata.append('name', data.name);
	fdata.append('geom', data.geom);
	fdata.append('geomall', data.geomall);
	fdata.append('user_id', data.user_id);
	fdata.append('dateCreated', data.dateCreated);
	fdata.append('category', data.usefor);
	fdata.append('description', data.description);
	fdata.append('gencode', 'XL' + Math.floor(100000 + Math.random() * 900000));

	fdata.append('address', null);
	fdata.append('imgpath', null);
	fdata.append('iconpath', null);
	fdata.append('latitude', null);
	fdata.append('longitude', null);
	let url = `${ROOT_URL}`;
	if (tool === 'Point') url = url + 'poi/poi/';
	else if (tool === 'LineString') url = url + 'line/line/';
	else url = url + 'polygon/polygon/';
	const request = axios.post(url, fdata, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function fetchListDrawing(tool, category) {
	let url = `${ROOT_URL}`;
	if (tool === 'Point') url = url + `poi/poi/`;
	else if (tool === 'LineString') url = url + `line/line/`;
	else url = url + `polygon/polygon/`;
	url = url + `?search=${category}`;
	const request = axios.get(url, cekAuth());
	// pushlogging("get " + url);
	return request;
}

export function deleteDraw(tool, id) {
	let url = `${ROOT_URL}`;
	if (tool === 'Point') url = url + `poi/poi/`;
	else if (tool === 'LineString') url = url + `line/line/`;
	else url = url + `polygon/polygon/`;
	url = url + `${id}/`;
	const request = axios.delete(url, cekAuth());
	return request;
}
